import React from 'react';

import './Contracts.scss';
import { Helmet } from 'react-helmet';
import { useGetEventsQuery } from './contractsApi.ts';
import ExternalLink from '../decorative/ExternalLink';
import {
  camelToNormalCase, formatAcc, formatEtherScanLink, formatTimeLength, networkIdToLabel, parseTimeDelta,
} from '../../services/utils.ts';
import PositionLink from '../decorative/PositionLink';
import TagIcon from '../decorative/TagIcon/TagIcon.tsx';
import TooltipWrapper from '../decorative/TooltipWrapper/TooltipWrapper';


const formatName = (event) => (event.actionName === 'Unknown' ? event.contractId : event.actionName);

function formatEvent(event) {
  switch (event.name) {
    case 'AddNewContract':
      return (
        <>
          <div>{camelToNormalCase(event.name)}</div>
          <div className="subtitle">
            Address: <PositionLink positionId={event.contractAddr} network={event.chainId} protocol="address">{formatAcc(event.contractAddr)}</PositionLink><br />
            Timelock: {formatTimeLength(event.waitPeriod)}
          </div>
        </>
      );
    case 'StartContractChange':
    case 'ApproveContractChange':
    case 'CancelContractChange':
      return (
        <>
          <div>{camelToNormalCase(event.name)}</div>
          <div className="subtitle">
            Address: <PositionLink positionId={event.oldContractAddr} network={event.chainId} protocol="address">{formatAcc(event.oldContractAddr)}</PositionLink>
            → <PositionLink positionId={event.newContractAddr} network={event.chainId} protocol="address">{formatAcc(event.newContractAddr)}</PositionLink>
            <br />
          </div>
        </>
      );
    case 'RevertToPreviousAddress':
      return (
        <>
          <div>{camelToNormalCase(event.name)}</div>
          <div className="subtitle">
            Revert to: <PositionLink positionId={event.previousAddress} network={event.chainId} protocol="address">{formatAcc(event.previousAddress)}</PositionLink>
            <br />
          </div>
        </>
      );
    case 'StartWaitPeriodChange':
      return (
        <>
          <div>Start Timelock Change</div>
          <div className="subtitle">
            Increase timelock to: {formatTimeLength(event.newWaitPeriod)}
          </div>
        </>
      );

    case 'ApproveWaitPeriodChange':
      return (
        <>
          <div>Approve Timelock Change</div>
          <div className="subtitle">
            Increase timelock from {formatTimeLength(event.oldWaitPeriod)} to: {formatTimeLength(event.newWaitPeriod)}
          </div>
        </>
      );
    default:
      return '';
  }
}

const Contracts = () => {
  const { data, error, isLoading } = useGetEventsQuery();
  // console.log(data);
  const waitPeriods = data && [...data].reverse().reduce((acc, event) => {
    if (event.newWaitPeriod) acc[`${event.chainId}-${formatName(event)}`] = event.newWaitPeriod;
    if (event.waitPeriod) acc[`${event.chainId}-${formatName(event)}`] = event.waitPeriod;
    return acc;
  }, {});
  return (
    <div className="contracts-wrapper">
      <Helmet>
        <title>Contract Updates | DeFi Saver</title>
      </Helmet>
      <div className="width-container">
        <h1>Contract Updates</h1>
        {error && JSON.stringify(error)}
        {isLoading && <div className="loader-wrapper">Loading...</div>}
        {data && (
        <>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>{/* network */}</th>
                  <th>Contract</th>
                  <th>Event</th>
                  <th>{/* explorer */}</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((event) => (
                  <tr>
                    <td>
                      <TooltipWrapper title={networkIdToLabel(event.chainId)}>
                        <TagIcon tag={networkIdToLabel(event.chainId)} />
                      </TooltipWrapper>
                    </td>
                    <td>
                      <div>
                        {event.actionName === 'Unknown'
                          ? event.contractId
                          : (<ExternalLink href={`https://github.com/defisaver/defisaver-v3-contracts/search?q=path%3A**%2F${event.actionName.replace(/ID$/, '')}*`} showIcon>{event.actionName}</ExternalLink>)}
                      </div>
                      <div className="subtitle">{parseTimeDelta(event.timestamp)}</div>
                    </td>
                    <td>
                      {formatEvent(event)}
                    </td>
                    <td>
                      <ExternalLink href={formatEtherScanLink(event.txHash, 'tx', event.chainId)} showIcon className="explorer-link" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
        )}
      </div>
    </div>
  );
};

Contracts.propTypes = {};

export default Contracts;
